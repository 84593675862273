import img1 from '../../assets/Services/extended-reality/Larger photo.jpg';
import img2 from '../../assets/Services/extended-reality/Virtual Meeting.png';
import img3 from '../../assets/Services/extended-reality/VR Training Modules.jpg';
import img4 from '../../assets/Services/extended-reality/Larger photo.jpg';

const VRSolutions = [

    {
        id: 1,
        img: img1,
        title: 'VR Training Modules',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        desc: 'Enhance learning outcomes with interactive, immersive training sessions.',
    },
    {
        id: 2,
        img: "",
        title: 'Web-Based VR Experiences',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://cw.avrlabs.io/Airplane/index.html',
        video: '',
        desc: 'Access VR content directly from any browser, ensuring global reach and seamless integration.',
    },
    {
        id: 3,
        img: "",
        title: 'Virtual Environments',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: 'https://player.vimeo.com/video/929219923?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0',
        desc: 'Tailor-made virtual spaces for product demonstrations, simulations, and education.'
    },
    {
        id: 4,
        img: "",
        title: 'Virtual Labs for Schools & Universities',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: 'https://player.vimeo.com/video/937239399?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0',
        desc: 'Boost engagement with fully immersive, hands-on learning experiences.'
    },
    {
        id: 5,
        img: img2,
        title: 'Virtual Meeting Platforms',
        desc: `Connect with customers or colleagues worldwide with our virtual meeting feature, enabling seamless communication and collaboration.`,
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: 's',
    },
    

]

export default VRSolutions;
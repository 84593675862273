import img1 from '../../assets/Services/extended-reality/AR Marker-Based Solutions.jpg';
import img2 from '../../assets/Services/extended-reality/VR Training.jpg';
import img3 from '../../assets/Services/extended-reality/VR Training Modules.jpg';
import img4 from '../../assets/Services/extended-reality/Larger photo.jpg';

const ARSolutions = [

    {
        id: 1,
        img: img1,
        title: 'AR Marker-Based Solutions',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        desc: 'Engage customers with custom AR markers that bring your products to life.',
    },
    {
        id: 2,
        img: "",
        title: 'Geo-Location AR',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: 'https://player.vimeo.com/video/936132185?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0',
        desc: 'Allow users to visualize products in real-world settings for enhanced decision-making.',
    },
    {
        id: 3,
        img: "",
        title: 'Product Configuration',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://cw.avrlabs.io/texture',
        video: '',
        desc: 'Empower customers to personalize products with real-time customization features.'
    },
    {
        id: 4,
        img: "",
        title: 'AR Marketing',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://portal.arviewz.com/products/620/modelviewer?arModel=true',
        video: '',
        desc: 'Elevate customer experiences with interactive brochures, menus, and more.'
    },
    

]

export default ARSolutions;
import React, { useState, useEffect, useRef } from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService2 from '../../components/about/AboutService2';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import about1Img from '../../assets/Services/extended-reality/Unlock.png';
import about2Img from '../../assets/Services/extended-reality/Step.png';
import token5DataImg from '../../assets/Services/extended-reality/VR Training.jpg';
import token12DataImg from '../../assets/Services/extended-reality/manufactoring.jpg';
import contactImg from '../../assets/Services/have-a-project2.png';
import SolutionCounter from '../../components/counter/SolutionCounter';
import Project from '../../components/project/Project';
import VRSolutions from '../../assets/fake-data/VRSolutions';
import ARSolutions from '../../assets/fake-data/ARSolutions';
import XRSolutions from '../../assets/fake-data/XRSolutions';
import DynamicModal from '../../components/DynamicModal';
import Roadmap2 from '../../components/roadmap/Roadmap2';
import Contact from '../../components/contact/Contact';

function ExtendedRealityNew(props) {
    const CounterStats = [
        {
            count: '3x ',
            title: "Increase customer engagement"
        },
        {
            count: "30% ",
            title: 'Improve learning retention'
        },
        {
            count: '25%',
            title: "Reduce operational costs"
        },
        {
            count:'40%',
            title:"Increase brand recall"
        },
        {
            count:'50%',
            title:"Improve remote collaboration efficiency"
        }
    ];
    const CounterStats2 = [
        {
            count: '20%',
            title: "Increase conversion rates"
        },
        {
            count: "30% ",
            title: 'Reduce product development cycles'
        },
        {
            count: '40%',
            title: "Reduce medical errors"
        },
        {
            count:'50%',
            title:"Increase property inquiries"
        },
        {
            count:'60%',
            title:"Increase student engagement"
        }
    ];
    const bannerData = {
        heading: 'Unlock Infinite Possibilities with Extended Reality (XR)',
        desc: "Transform Your Business with Cutting-Edge AR, VR, MR and XR Solutions",
        desc1: "<span class='span-inside-both'>Over 15 years of experience</span> | <span class='span-inside-both'>50+ satisfied clients worldwide</span>",
        button: true,
        btnTitle: "Talk To Our XR Experts",
        link: '/contact',
        images:[],
    };
    const aboutservice2Data2 = {
        heading: 'Step into Immersive Mixed Reality and Virtual Reality (VR) Experiences',
        subheading: "",
        desc: "Step into a world where reality meets innovation. Whether you're in retail, education, healthcare, or real estate, we empower you to transform your customer engagement, training, and operational efficiency.",
        btn: true,
        btnTitle: "Book Your FREE Consultation",
        link: 'https://calendly.com/raed-avr/',
        targetBlank: true,
        classcustom: true,
        img: about2Img
    };
    const aboutservice2Data1 = {
        heading: 'Unlock the Potential of Augmented Reality (AR)',
        subheading: "",
        desc: "We push the boundaries of reality with our cutting-edge Augmented Reality solutions. Elevate your customer experience and enhance brand recognition with our innovative AR experiences that seamlessly blend with the real world.",
        btn: false,
        btnTitle: "More About Us",
        link: '/about',
        img: about1Img
    };

    const token2Data5 = {
        // subheading: 'Token Information',
        heading: 'XR Reality Simulators',
        desc: `Practice real-world skills in a safe environment with custom XR reality simulators, improving proficiency and reducing training costs.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: 'https://player.vimeo.com/video/1032997068?h=9fcae8dfcc&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        img: "",
        isShape: false
    }

    const token2Data12 = {
        // subheading: 'Token Information',
        heading: 'Manufacturing and Engineering',
        desc: `Streamline product design and prototyping with VR visualization tools, remote collaboration, and interactive 3D modeling that accelerate time-to-market.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token12DataImg
    }

    const contactData = {
        heading: 'Have a project?',
        desc: 'Book your FREE Consultation now to transform your business before it’s too late!',
        btn: true,
        btnTitle: 'Contact us',
        link: '/contact',
        img: contactImg
    }
    const dataBlockVR = {
        subheading: 'OUR XR SERVICES',
        heading: 'Virtual Reality (VR) Solutions',
        btn: true,
        btnTitle: "Discuss your First VR Project Today",
        link: '/contact',
    }
    const dataBlockAR = {
        subheading: '',
        heading: 'Augmented Reality (AR) Solutions',
        btn: true,
        btnTitle: "Book a demo Call",
        link: '/contact',
    }
    const dataBlockXR = {
        subheading: '',
        heading: 'Industry-Specific XR Applications',
        btn: true,
        btnTitle: "Get your customized Solutions Now",
        link: '/contact',
    }
    const [showModal, setShowModal] = useState(false);
    const [remindMeLater, setRemindMeLater] = useState(false);
    const [delayDuration, setDelayDuration] = useState(40000);
    const contact2Ref = useRef(null);

    const handleHideModal = () => {
        setShowModal(false);
        setRemindMeLater(true);

        // Set a timer to show the modal again after the delay
        setTimeout(() => {
            setShowModal(true);
            setRemindMeLater(false);
            // setDelayDuration(80000);
        }, 50000);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (contact2Ref.current) {
                const contact2Top = contact2Ref.current.getBoundingClientRect().top;
                if (contact2Top <= window.innerHeight) {
                setShowModal(true);
                window.removeEventListener("scroll", handleScroll);
                }
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <ServiceToken data={aboutservice2Data2} />
            <div className="my-5 py-5">
                <Project data={VRSolutions} blockTop={dataBlockVR}/>

                <ServiceToken data={token2Data5} />

                <Project data={ARSolutions} blockTop={dataBlockAR}/>
                <div className='main-sec-xr' ref={contact2Ref}>
                    <Roadmap2 headerData={dataBlockXR} data={XRSolutions} />
                </div>
            </div>

            <SolutionCounter data={CounterStats} icon="false" showimg="false"  />
            <section className="my-5 py-5">
                <AboutService2 data={aboutservice2Data1} />
            </section>

            {/* <section className="my-5 p-5">
                <h2 className="text-center">Explore the Possibilities with Extended Reality</h2>
            </section>
            <ServiceToken2 data={token2Data12} /> */}
            
            <div className="mb-5">
                <SolutionCounter data={CounterStats2} className="my-5" showimg="false" />
            </div>
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />

            <DynamicModal
                show={showModal}
                onHide={remindMeLater ? undefined : handleHideModal}
                title="Schedule FREE Demo Call Today!"
                bodyContent={
                    <Contact />
                }
                size="xl"
                classNameAll="contact-show-cls-modal"
                remindLater= "Remind me later"
            />
        </div>
    );
}

export default ExtendedRealityNew;
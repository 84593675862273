import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DynamicModal = ({ show, onHide, title, bodyContent, footerButtons, size = "md", classNameAll = "", video = "", iframe = "", heading = "", img = "", remindLater = "" }) => {
    return (
        <Modal show={show} onHide={onHide} size={size} className={`main-model-box ${classNameAll}`}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>

                {!remindLater && <button
                    type="button"
                    className="btn-close"
                    onClick={onHide}
                    aria-label="Close"
                >
                    <span className="icon-cross"></span>
                </button>}
                {remindLater && <button
                    type="button"
                    class="action-btn"
                    onClick={onHide}
                    aria-label="Close"
                >
                    <span>{remindLater}</span>
                </button>}
                
            </Modal.Header>
            <Modal.Body>
                {bodyContent}
                {iframe && (<div style={{ position: "relative" }} className="iframe mt-2">
                    <iframe
                        src={iframe}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{
                            width: "100%",
                        }}
                        title={heading}
                        className="embed-responsive-item"
                    ></iframe>
                </div>
                )}
                {video && (
                    <div style={{ position: "relative" }} className="video mt-2">
                        <iframe
                            src={video}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            style={{
                                width: "100%",
                            }}
                            title={heading} className="embed-responsive-item"
                        ></iframe>
                    </div>
                )}
                {(img) &&
                    <img src={img} alt="AVRLabs" className='main-img-modal mt-2' />
                }
            </Modal.Body>
            {footerButtons && <Modal.Footer>
                {footerButtons?.map((btn, idx) => (
                    <Button
                        key={idx}
                        variant={btn.variant || "primary"}
                        onClick={btn.onClick}
                    >
                        {btn.label}
                    </Button>
                ))}
            </Modal.Footer>}
        </Modal>
    );
};

export default DynamicModal;

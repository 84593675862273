import img1 from '../../assets/Services/extended-reality/png/explore-with-extended-reality.png';
import img2 from '../../assets/Services/extended-reality/png/event-and-exhibition.png';
import img3 from '../../assets/Services/extended-reality/png/healthcare-training.png';
import img4 from '../../assets/Services/extended-reality/png/architecture.png';
import img5 from '../../assets/Services/extended-reality/png/education.png';
import img6 from '../../assets/Services/extended-reality/png/manufactoring.png';

const XRSolutions = [

    {
        id: 1,
        img: img1,
        title: 'Retail & Showroom',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '/industries/retail-stores',
        iframe: '',
        video: '',
        learnMore: "Learn more",
        desc: 'Transform the shopping experience with AR try-ons, virtual showrooms, and interactive demos that drive conversions.',
    },
    {
        id: 2,
        img: img2,
        title: 'Events & Exhibitions',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '/industries/events',
        iframe: '',
        video: '',
        learnMore: "Learn more",
        desc: 'Stand out with XR-powered booths, virtual demos, and interactive AR showcases that captivate attendees and boost engagement.',
    },
    {
        id: 3,
        img: img3,
        title: 'Healthcare & Medical Training',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '/industries/healthcare',
        iframe: '',
        video: '',
        learnMore: "Learn more",
        desc: 'Improve patient outcomes with immersive simulations, virtual surgeries, and diagnostic tools.'
    },
    {
        id: 4,
        img: img4,
        title: 'Real Estate & Architecture',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '/industries/real-estate',
        iframe: '',
        video: '',
        learnMore: "Learn more",
        desc: 'Showcase properties with 360° virtual tours, AR floor plans, and dynamic visualizations.'
    },
    {
        id: 5,
        img: img5,
        title: 'Education & Training',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '/industries/education',
        iframe: '',
        video: '',
        learnMore: "Learn more",
        desc: 'Boost student engagement with VR field trips, simulations, and gamified content.'
    },
    {
        id: 6,
        img: img6,
        title: 'Manufacturing and Engineering',
        isLink: false,
        classCustom: 'inner-box',
        btn: false,
        btnTitle: "But Token",
        link: '/industries/education',
        iframe: '',
        video: '',
        learnMore: "",
        desc: 'Streamline product design and prototyping with VR visualization tools, remote collaboration, and interactive 3D modeling that accelerate time-to-market.'
    },
    
]

export default XRSolutions;